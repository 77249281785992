import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from 'universal-cookie'
import { isArray } from "lodash";

const expandChildQuery = (expand) => {
    return "$expand=" + Object.entries(expand).map(
        (o) => `${o[0]}
            ${o[1].fields
                ? `($filter=IsDeleted ne true;${o[1].top ? `$top=${o[1].top};` : ""}$select=${o[1].fields};${o[1].expand
                    ? expandChildQuery(o[1].expand)
                    : o[1].filter ? `($filter=${o[1].filter})` : ""})`
                : `${o[1].expand
                    ? `($filter=IsDeleted ne true;${o[1].top ? `$top=${o[1].top};` : ""}${expandChildQuery(o[1].expand)})`
                    : o[1].filter ? `($filter=${o[1].filter})` : ""}`}`
    ).join(",").trim();
};

const baseAPIQuery = ({
    entity,
    subEntity = "",
    filter = "",
    sort = [],
    skip = 0,
    top = 10,
    expand = {},
    query = "",
    globalFilter = {},
}, allRecords) => {
    let finalUrl = `${entity}`;
    let filterQuery = "$filter=IsDeleted ne true";
    if (subEntity !== "") {
        finalUrl += `/${subEntity}`;
    }
    if (Object.entries(globalFilter).length > 0) {
        if (
            globalFilter.globalSearchKeyword &&
            globalFilter.globalSearchKeyword !== ""
        ) {
            let filterData = [];
            globalFilter.columns.forEach((value) => {
                if (
                    value.Header !== "Action" &&
                    value.Header !== "Media" &&
                    typeof value.accessor !== "function" &&
                    value.type !== "Boolean"
                ) {
                    if (value.type !== "Number") {
                        filterData.push(
                            `contains(${value.accessor.replaceAll(".", "/")},'${globalFilter.globalSearchKeyword
                            }')`
                        );
                    } else if (!isNaN(globalFilter.globalSearchKeyword)) {
                        filterData.push(
                            `${value.accessor} eq ${globalFilter.globalSearchKeyword}`
                        );
                    }
                }
            });
            filterQuery += ` and (${filterData.join(" or ")})`;
        }
    }
    if (filter !== "") {
        filterQuery += ` and (${filter})`;
    }
    finalUrl += allRecords ? '' : `?$skip=${skip}&$top=${top}&$count=true`;
    if (query !== "") {
        finalUrl = `${finalUrl}&${query}`;
    }
    if (sort.length > 0) {
        let sortQuery = allRecords ? "?orderby=" : "&$orderby=";
        sortQuery += sort.map((value) => {
            return `${value.columnName} ${value.direction}`;
        });
        finalUrl += sortQuery;
    } else {
        finalUrl += allRecords ? "?$orderby=Id desc" : "&$orderby=Id desc";
    }
    if (Object.entries(expand).length > 0) {
        let expandQueryStr = "";
        expandQueryStr = "&" + expandChildQuery(expand);
        finalUrl += expandQueryStr;
        Object.entries(expand).forEach((o) => {
            if (!o[1].expand && !o[1].filter) {
                filterQuery += ` and ${o[0]}/IsDeleted ne true`;
            }
        });
    }
    finalUrl += `&${filterQuery}`;
    return finalUrl
};

export const vtsgApi = createApi({
    reducerPath: "vtsgApi",
    tagTypes: (result, error, arg) => (arg.tag ? [arg.tag] : []),
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API,
        prepareHeaders: (headers) => {
            const cookies = new Cookies()   
            const token = cookies.get('accessToken')
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: builder => ({
        getRecords: builder.query({
            query: (params) => {
                const finalUrl = baseAPIQuery(params, false);
                return {
                    url: `${finalUrl}`,
                    method: "GET",
                    headers: {
                        'content-type': `application/json;odata=verbose`,
                    },
                }
            },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
        getRecordsWithoutOData: builder.query({
            query: ({ entity, expand = "", columnFilter = "" }) => {
                var filterUrlString = "";
                var filterForAllColumns = { ...columnFilter }
                if (columnFilter && columnFilter !== "") {
                    filterUrlString = Object.entries(filterForAllColumns)
                        .map(([key, value]) => {
                            if (isArray(value)) {
                                var urlString = ' AND (';
                                value.map((item, index) => {
                                    index === 0 ?
                                        urlString += `${key} eq '${item}'`
                                        : urlString += ` OR ${key} eq '${item}'`
                                }
                                );
                                urlString += ")";
                                return (urlString)
                            }
                            if (typeof (value) === 'string') {
                                return (` AND contains(${key},'${value}')`)
                            }
                            return (` ${key} eq ${value}`)
                        })
                }
                if (expand !== "") {
                    filterUrlString += `&$expand=${expand}`
                }
                var newUrl = "";

                newUrl = `${entity}${filterUrlString}`
                var newUrl1 = `${entity}?$filter=IsDeleted ne true AND ${filterUrlString}`
                return {
                    url: columnFilter !== "" ? newUrl1 : `${newUrl}`,
                    method: 'GET',
                }
            },
            providesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),

        addRecord: builder.mutation({
            query: params => ({
                url: `${params.entity}`,
                method: "POST",
                body: params.data,
            }),
            invalidatesTags: (result, error, arg) => (arg.tag ? [arg.tag] : []),
        }),
    }),
})

export const { useLazyGetRecordsQuery, useAddRecordMutation, useLazyGetRecordsWithoutODataQuery } = vtsgApi
