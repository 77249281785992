import React, { Fragment, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Logo from "../../../../components/logo"
import Button from "./../../../../components/menu/ui/button"
import Clickable from "./../../../../components/menu/ui/clickable"
import BurgerButton from "./../../../../components/menu/ui/burger-button"
import OffCanvas, {
    OffCanvasHeader,
    OffCanvasBody,
} from "../../../../components/menu/ui/off-canvas"
// import { MainMenu, MobileMenu } from "../../../../components/menu"
// import { useAuth0 } from "@auth0/auth0-react"
import Cookies from "universal-cookie"
import { useAuth0 } from "@auth0/auth0-react"

import {
	HeaderWrap,
	HeaderOuter,
	FixedHeader,
	HeaderMain,
	HeaderMiddle,
	HeaderRight,
	HeaderRightInner,
	HeaderNavigation,
	HeaderElement,
	FixedHeaderHeight,
} from "./header.style"
import { MainMenu } from "../../../../components/menu/main-menu"
import { MobileMenu } from "../../../../components/menu/mobile-menu"
import BackgroundImage from "../../../../assets/images/logo/VinceTheSignGuyHeaderNew.png";

const Header = props => {
    const {
        loginWithRedirect,
        isAuthenticated,
        logout,
        getAccessTokenSilently,
        isLoading
    } = useAuth0()
    const [offCanvasOpen, setOffcanvasOpen] = useState(false)
    const [headerInnerOpen, setHeaderInnerOpen] = useState(false)
    const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0)
    const [totalHeaderHeight, setTotalHeaderHeight] = useState(0)
    const [sticky, setSticky] = useState(false)
    const headerRef = useRef(null)
    const fixedRef = useRef(null)
    const offCanvasHandler = () => {
        setOffcanvasOpen(prevState => !prevState)
    }
    const headerInnerHandler = () => {
        setHeaderInnerOpen(prevState => !prevState)
    }

    useEffect(() => {
        setFixedHeaderHeight(fixedRef.current.clientHeight)
        setTotalHeaderHeight(headerRef.current.clientHeight)
    }, [fixedHeaderHeight])

    useEffect(() => {
        const scrollHandler = () => {
            let scrollPos = window.scrollY
            if (scrollPos > totalHeaderHeight) {
                setSticky(true)
            }

            if (scrollPos < fixedHeaderHeight) {
                setSticky(false)
            }
        }
        window.addEventListener("scroll", scrollHandler)
        return () => {
            window.removeEventListener("scroll", scrollHandler)
        }
    }, [sticky, totalHeaderHeight, fixedHeaderHeight])

	useEffect(() => {
        if (isAuthenticated) {
            let accessToken = ""
			const selfInvloked = async () => {
				const getToken = async () => {
					accessToken = await getAccessTokenSilently({
						audience: process.env.REACT_APP_AUTH0_AUDIENCE,
					})
					const cookies = new Cookies()
					cookies.set("accessToken", accessToken)
				}
				await getToken()
			}
			selfInvloked()
		}
	}, [isAuthenticated, getAccessTokenSilently, isLoading])

    const menuData = {
        "allLandingmenuJson": {
            "edges": [
                {
                    "node": {
                        "text": "EXPLORE MORE",
                        "link": "/",
                        "id": "78a7ca9d-9bdd-5667-8267-6c5ee256b761",
                        "megamenu": [
                            {
                                "title": "Get to Know Our Humor",
                                "submenu": [
                                    {
                                        "link": "/topicalPuns",
                                        "text": "Topical Puns"
                                    },
                                    {
                                        "link": "/lightHumor",
                                        "text": "Light Humor"
                                    },
                                    {
                                        "link": "/trades",
                                        "text": "Professional Trades"
                                    },
                                    {
                                        "link": "/events",
                                        "text": "Holidays and Events"
                                    }
                                ]
                            },
                            {
                                "title": "Media Events",
                                "submenu": [
                                    {
                                        "link": "/video-player",
                                        "text": "Channel 9 Interview"
                                    },
                                    {
                                        "link": "/vegan",
                                        "text": "Going Vegan Incident"
                                    },
                                    {
                                        "link": "/video-player",
                                        "text": "Television Appearances"
                                    },
                                    {
                                        "link": "/audio-player",
                                        "text": "Radio Interviews"
                                    }
                                ]
                            },
                            {
                                "title": "Community Building",
                                "submenu": [
                                    {
                                        "link": "https://signpuns.com/case-study/indian-hills-colorado",
                                        "text": "Indian Hills, CO"
                                    },
                                    {
                                        "link": "https://www.signpuns.com/we-can-run-your-community-sign",
                                        "text": "League City, TX"
                                    },
                                    {
                                        "link": "/privacy-policy",
                                        "text": "Privacy & policy"
                                    }
                                ]
                            },
                            {
                                "title": "Fans of Vince the Sign Guy",
                                "submenu": [
                                    {
                                        "link": "/fans/testimonials",
                                        "text": "Testimonials"
                                    },
                                    {
                                        "link": "/fans",
                                        "text": "Fans"
                                    },
                                    {
                                        "link": "/fans/frenemies",
                                        "text": "Frenemies"
                                    }
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    }

    const menuArr = menuData && menuData.allLandingmenuJson.edges
    // const menuArr = [
    //     {
    //         "node": {
    //             "text": "Latest Signs",
    //             "link": "#section-demos",
    //             "id": "0e67c20a-0040-57d8-8080-22a560948210",
    //             "megamenu": null
    //         }
    //     },
    //     {
    //         "node": {
    //             "text": "Vince The Sign Guy",
    //             "link": "/",
    //             "id": "e603f6ad-7d2a-5055-ad20-f8a4ababef6b",
    //             "megamenu": [
    //                 {
    //                     "title": "Get to Know Our Humor",
    //                     "submenu": [
    //                         {
    //                             "link": "/topicalPuns",
    //                             "text": "Topical Puns"
    //                         },
    //                         {
    //                             "link": "/lightHumor",
    //                             "text": "Light Humor"
    //                         },
    //                         {
    //                             "link": "/trades",
    //                             "text": "Professional Trades"
    //                         },
    //                         {
    //                             "link": "/events",
    //                             "text": "Holidays and Events"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     "title": "Media Events",
    //                     "submenu": [
    //                         {
    //                             "link": "/video-player",
    //                             "text": "Channel 9 Interview"
    //                         },
    //                         {
    //                             "link": "/vegan",
    //                             "text": "Going Vegan Incident"
    //                         },
    //                         {
    //                             "link": "/video-player",
    //                             "text": "Television Appearances"
    //                         },
    //                         {
    //                             "link": "/audio-player",
    //                             "text": "Radio Interviews"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     "title": "Community Building",
    //                     "submenu": [
    //                         {
    //                             "link": "https://signpuns.com/case-study/indian-hills-colorado",
    //                             "text": "Indian Hills, CO"
    //                         },
    //                         {
    //                             "link": "https://www.signpuns.com/we-can-run-your-community-sign",
    //                             "text": "League City, TX"
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     "title": "Fans of Vince the Sign Guy",
    //                     "submenu": [
    //                         {
    //                             "link": "/fans/testimonials",
    //                             "text": "Testimonials"
    //                         },
    //                         {
    //                             "link": "/fans",
    //                             "text": "Fans"
    //                         },
    //                         {
    //                             "link": "/fans/frenemies",
    //                             "text": "Frenemies"
    //                         }
    //                     ]
    //                 }
    //             ]
    //         }
    //     },
    //     {
    //         "node": {
    //             "text": "Contact Us",
    //             "link": "/contact-us",
    //             "id": "fb16d265-5d68-5bdc-ad34-d36c33b8fc30",
    //             "megamenu": null
    //         }
    //     },
    //     {
    //         "node": {
    //             "text": "Add Puns",
    //             "link": "/addPuns",
    //             "id": "653f2a57-8bd0-5e58-840b-f4ec12249f52",
    //             "megamenu": null
    //         }
    //     }
    // ]
    const { headerStyle, transparent } = props
    const {
        logoStyle,
        burgerBtnElStyle,
        clickAbleElStyle,
        clickAble,
    } = headerStyle

    const heroData = {
        "landingJson": {
            "bgHeaderImage": {
                "childImageSharp": {
                    "fluid": {
                        "src": `${BackgroundImage}`,
                    }
                }
            },
        }
    }

    const { bgHeaderImage } = heroData.landingJson;
    let { ...bgm } = bgHeaderImage.childImageSharp.fluid

    return (
        <Fragment>
            <HeaderWrap transparent={transparent} ref={headerRef} isSticky={sticky}>
                <HeaderOuter {...bgm}>
                    <FixedHeader {...bgm} ref={fixedRef}>
                        <Container fluid>
                            <Row>
                                <Col lg={12}>
                                    <HeaderMain>
                                        <HeaderMiddle>
                                            <HeaderNavigation>
                                                <MainMenu
                                                    menuData={menuArr}
                                                    layout={4}
                                                    whiteColor={!sticky && transparent}
                                                    isSticky={sticky}
                                                />
                                            </HeaderNavigation>
                                        </HeaderMiddle>
                                        <HeaderRight>
                                            <HeaderRightInner isOpen={headerInnerOpen}>
                                                {/* <HeaderElement>
                                                    <Button
                                                        bgcolor="#1CC0DB"
                                                        color="black"
                                                        stickyBtn={sticky}
                                                        to="store"
                                                        rel="noopener noreferrer"
                                                        fontWeight="500"
                                                        fontSize="20px"
                                                        height="40px"
                                                        size="xsmall"
                                                        lineheight="40px"
                                                        p="0 20px"
                                                    >
                                                        Get The App
                                                    </Button>
                                                </HeaderElement> */}
												<HeaderElement>
													<Button
														style={{ margin: "0 10px 0 0" }}
														stickyBtn={sticky}
														to="https://vincethesignguy.myshopify.com/"
														target="_blank"
														bgcolor="#1CC0DB"
														color="black"
														rel="noopener noreferrer"
														fontSize="17px"
														fontWeight="500"
														height="40px"
														size="xsmall"
														lineheight="40px"
														p="0 20px"
													>
														SHOP NOW
													</Button>
												</HeaderElement>
												<HeaderElement>
													{isAuthenticated ? (
														<Button
															style={{ margin: "0 10px 0 0" }}
															stickyBtn={sticky}
															bgcolor="#1CC0DB"
															color="black"
															rel="noopener noreferrer"
															fontWeight="500"
															fontSize="17px"
															height="40px"
															size="xsmall"
															lineheight="40px"
															p="0 20px"

															onClick={() => {
																logout({ returnTo: window.location.origin })
																let cookies = new Cookies()
																cookies.remove("accessToken")
															}}
														>
															LOGOUT
														</Button>
													) : (
														<Button
															style={{ margin: "0 10px 0 0" }}
															stickyBtn={sticky}
															bgcolor="#1CC0DB"
															color="black"
															rel="noopener noreferrer"
															fontWeight="500"
															fontSize="17px"
															height="40px"
															size="xsmall"
															lineheight="40px"
															p="0 17px"
															onClick={loginWithRedirect}
														>
															LOGIN
														</Button>
													)}
												</HeaderElement>
											</HeaderRightInner>
											<HeaderElement
												{...burgerBtnElStyle}
												visibility={{ default: "false", lg: "true" }}
											>
												<BurgerButton
													whiteColor={!sticky}
													onClick={offCanvasHandler}
												/>
											</HeaderElement>
											<HeaderElement
												{...clickAbleElStyle}
												visibility={{ default: "false", sm: "true" }}
											>
												<Clickable onClick={headerInnerHandler} {...clickAble}>
													<i className="far fa-ellipsis-h-alt"></i>
												</Clickable>
											</HeaderElement>
										</HeaderRight>
									</HeaderMain>
								</Col>
							</Row>
						</Container>
					</FixedHeader>
					<FixedHeaderHeight height={fixedHeaderHeight} />
				</HeaderOuter>
			</HeaderWrap>
			<OffCanvas
				scrollable={true}
				isOpen={offCanvasOpen}
				onClick={offCanvasHandler}
			>
				<OffCanvasHeader onClick={offCanvasHandler}>
					<Logo darkLogo align={{ default: "flex-start" }} />
				</OffCanvasHeader>
				<OffCanvasBody>
					<MobileMenu menuData={menuArr} />
				</OffCanvasBody>
			</OffCanvas>
		</Fragment>
	)
}

Header.propTypes = {
    headerStyle: PropTypes.object,
}

Header.defaultProps = {
    headerStyle: {
        logoStyle: {
            align: {
                default: "flex-start",
            },
        },
        burgerBtnElStyle: {
            pl: "30px",
        },
        clickAbleElStyle: {
            pl: "15px",
        },
        clickAble: {
            fontsize: "20px",
            color: "#6D70A6",
        },
    },
}

export default Header
