import React from "react"
import PropTypes from "prop-types"



import {
  PreviewWrap,
  PreviewInner,
  FrameScreen,
  Dots,
  PreviewThumb,
  PreviewOverlay,
  PreviewLink,
  ButtonWrap,
  PreviewInfo,
  HeadingWrap,
  CardWrapper,
  CardContent,
  EmptyPun
} from "./demo.style"

const Demo = ({ content, punImage }) => {

  return (
    <PreviewWrap>
      <PreviewInner>
        <FrameScreen>
          {/* {(path && !coming_soon) && <PreviewLink path={path}>{title}</PreviewLink>} */}
          <Dots>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </Dots>
          <PreviewThumb>
            {content && (
              <div>
                <img src={punImage} alt="" srcSet="" height="100px" width="100%" />
                <CardWrapper>
                  <CardContent>
                    {content.Line1 === '' ? <EmptyPun></EmptyPun> : content.Line1}
                  </CardContent>
                  <CardContent>
                    {content.Line2 === '' ? <EmptyPun></EmptyPun> : content.Line2}
                  </CardContent>
                  <CardContent>
                    {content.Line3 === '' ? <EmptyPun></EmptyPun> : content.Line3}
                  </CardContent>
                  <CardContent>
                    {content.Line4 === '' ? <EmptyPun></EmptyPun> : content.Line4}
                  </CardContent>
                  <CardContent>
                    {content.Line5 === '' ? <EmptyPun></EmptyPun> : content.Line5}
                  </CardContent>
                </CardWrapper>
              </div>
            )}
            {/* <PreviewOverlay> */}
            {/* {(path && !coming_soon) && (
                                <ButtonWrap>
                                    <Button to={path} skin="light">Share This</Button>
                                </ButtonWrap>
                            )}
                            {coming_soon && (
                                <ButtonWrap>
                                    <Button hover="false" skin="light">Coming Soon</Button>
                                </ButtonWrap>
                            )} */}
            {/* </PreviewOverlay> */}
          </PreviewThumb>
          <PreviewInfo>
            {content && <HeadingWrap>{content?.UserName}</HeadingWrap>}
          </PreviewInfo>
        </FrameScreen>
      </PreviewInner>
    </PreviewWrap>
  )
}


export default Demo