import React from "react"
import { Provider } from "react-redux"
import { ThemeProvider } from "styled-components"
import { ToastContainer } from "react-toastify"
import { Auth0Provider } from "@auth0/auth0-react"
import { GlobalStyle } from "../../assets/css/main-style"
import ScrollToTop from "../../components/menu/ui/scroll-to-top"
import { store } from "./../../store/store"
import { theme } from "../theme"
import '../../assets/fonts/cerebrisans/cerebrisans.css'
import "./../../assets/fonts/font-awesome/font-awesome.css"
import "../../assets/css/bootstrap.css"
import "react-toastify/dist/ReactToastify.css"

const Layout = ({ children }) => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
                    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                    redirectUri={process.env.REACT_APP_LOGIN_REDIRECT}
                    useRefreshTokens={true}
                    cacheLocation="localstorage"
                >
                    <div className="wrapper">
                        <GlobalStyle />
                        {children}
                        <ScrollToTop />
                    </div>
                    <ToastContainer />
                </Auth0Provider>
            </ThemeProvider>
        </Provider>
    )
}

export default Layout
