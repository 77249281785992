import React from "react";
import { Image } from "react-bootstrap";
// import Img from "gatsby-image";

const NonStretchedImage = props => {
    let normalizedProps = props
    let alignment;
    if (props.align === 'right') {
        alignment = '0 0 0 auto'
    } else if (props.align === 'left') {
        alignment = '0 auto 0 0'
    } else {
        alignment = '0 auto'
    }

    normalizedProps = { ...normalizedProps.fluid, aspectRatio: 1 }
    if (props.fluid && props.fluid.presentationwidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                position: props.isAbsolute ? 'absolute' : 'relative',
                width: props.isAbsolute ? props.fluid.presentationwidth : '',
                maxWidth: props.fluid.presentationwidth,
                margin: alignment,
            },
        }
    }
    let { base64, ...normalizedImg } = normalizedProps.fluid
    return <Image {...normalizedImg} />
}

export default NonStretchedImage;