import React, { useState } from 'react'
import Feature from '../components/landing/feature'
import Hero from '../components/landing/hero'
import DispalyPun from '../components/landing/demo-area'
import Header from '../containers/layout/header/header-four'
import BlogLayout from '../components/landing/blog-layout'
import Plugins from '../components/menu/ui/plugins'
import Footer from '../containers/layout/footer/footer-two'
import SEO from './../components/seo'
import Advertisment from '../components/Advertisment/Advertisment'

const HomePage = () => {

    const [currentPun, setCurrentPun] = useState(null)

    return (
        <div>
            <SEO />
            <Header transparent />
            <main className="site-wrapper-reveal">
                <Hero setCurrentPun={setCurrentPun}/>
                <Feature />
                <Advertisment />
                <DispalyPun currentPun={currentPun} />
                <BlogLayout />
                <Plugins />
            </main>
            <Footer />
        </div>
    )
}

export default HomePage