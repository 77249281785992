import React from 'react';
import PropTypes from 'prop-types'
import FeatureBox from '../../../components/box-image/layout-three'
import SwiperSlider from './../../menu/ui/swiper'
import { FeatureWrapper } from './feature.style'
import Calendars from '../../../data/images/icons/2022Calendar.png'
import iconMerchandise from '../../../data/images/icons/iconMerchandise.png'
import iconSignPunsLogo from '../../../data/images/icons/iconSignPunsLogo.png'
import iconSignPun from '../../../data/images/icons/iconSignPun.png'


const Feature = ({ slider }) => {
  // const featureData = useStaticQuery(graphql`
  //   query featureQuery {
  //     landingJson(id: {eq: "site_features"}) {
  //       id
  //       items {
  //         title
  //         desc
  //         path
  //         image {
  //           childImageSharp {
  //             fixed(width: 80) {
  //               src
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }    
  //   `)

  const features = [
    {
      "title": "Calendars",
      "desc": "The Annual Calendar has arrived.  Grab yours today...",
      "path": "https://vincethesignguy.myshopify.com/",
      "image": {
        "childImageSharp": {
          "fixed": {
            "src": Calendars
          }
        }
      }
    },
    {
      "title": "Merchandise",
      "desc": "Grab the latest Vince the Sign Guy swag at our apparel shop...",
      "path": "https://vincethesignguy.myshopify.com/",
      "image": {
        "childImageSharp": {
          "fixed": {
            "src": iconMerchandise
          }
        }
      }
    },
    {
      "title": "Community Uplift",
      "desc": "Small towns are what we're about and we love to support you with your signs...",
      "path": "https://signpuns.com/",
      "image": {
        "childImageSharp": {
          "fixed": {
            "src": iconSignPunsLogo
          }
        }
      }
    },
    {
      "title": "VinceTheSignGuy",
      "desc": "Every day we post interesting and light-hearted humor for our audience...",
      "path": "https://facebook.com/vincethesignguy",
      "image": {
        "childImageSharp": {
          "fixed": {
            src: iconSignPun
          }
        }
      }
    }
  ]

  return (
    <FeatureWrapper>
      <SwiperSlider settings={slider}>
        {features.map((feature, i) => {
          return (
            <div className="item" key={`feature-${i}`}>
              <FeatureBox
                title={feature.title}
                desc={feature.desc}
                imageSrc={feature.image.childImageSharp.fixed.src}
                path={feature.path}
              />
            </div>
          )
        })}
      </SwiperSlider>
    </FeatureWrapper>
  )
}

Feature.propTypes = {
  slider: PropTypes.object
}

Feature.defaultProps = {
  slider: {
    slidesPerView: 4,
    loop: true,
    pagination: false,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      576: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1500: {
        slidesPerView: 4
      }
    }
  }
}

export default Feature; 