import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser';
import Heading from '../../../components/menu/ui/heading'
import Text from '../../../components/menu/ui/text'
import Button from '../../../components/menu/ui/button'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button1 from 'react-bootstrap/Button';
import Image from '../../../components/image'
import VinceWithHorses from '../../../data/images/vince/VinceWithHorses.jpg'
import { BlogLayoutWrap, ImageWrap, BlogLayoutTextWrap, ButtonWrap } from './blog-layout.style'
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from 'react-router-dom';

const BlogLayout = ({ titleStyle, textStyle }) => {
    // const blogData = useStaticQuery(graphql`
    //     query BlogQuery {
    //         landingJson(id: {eq: "blogLayout"}) {
    //             title
    //             path
    //             desc
    //             idF
    //             image {
    //                 childImageSharp {
    //                     fluid(maxWidth: 922, maxHeight: 760, quality: 100){
    //                         presentationWidth
    //                         presentationHeight
    //                         ...GatsbyImageSharpFluid_withWebp
    //                     }
    //                 }
    //             }
    //         }
    //     }      
    // `);
    const blogData = {
        "landingJson": {
            "title": "<span>Vince the Sign Guy</span> is Available for Interviews and Social Events",
            "path": "mailto:vince@signpuns.com",
            "desc": "Building your community is what we do.  Vince the Sign Guy is happy to review sponsorship requests and community involvement projects where his unique blend of lighthearted humor can uplift and inspire.",
            "id": "blogLayout",
            "image": {
                "childImageSharp": {
                    "fluid": {
                        "presentationwidth": 922,
                        "presentationheight": 760,
                        "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFJOpKTAv8A/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRIf/aAAgBAQABBQL0g8WtdGbaNKrMr//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAEEAwAAAAAAAAAAAAAAADEAAQIQISIy/9oACAEBAAY/AssVrI11Ki6//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAIVGBMf/aAAgBAQABPyFEqEa1Sg9yCl843UF7cIXAx928G//aAAwDAQACAAMAAAAQnO//xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAEDAQE/EBdX/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARQf/aAAgBAgEBPxAzF//EABoQAQEAAwEBAAAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8Q7IIo+b2phUppYAqaDtMV+rcRYeGDU4CacUk1l7sxYk1AfD7n/9k=",
                        "aspectRatio": 1.2157894736842105,
                        "src": VinceWithHorses,
                        "srcSet": `${VinceWithHorses} 231w,\n${VinceWithHorses} 461w,\n${VinceWithHorses} 922w,\n${VinceWithHorses} 1336w`,
                        "srcwebp": VinceWithHorses,
                        "srcsetwebp": `${VinceWithHorses} 231w,\n${VinceWithHorses} 461w,\n${VinceWithHorses} 922w,\n${VinceWithHorses} 1336w`,
                        "sizes": "(max-width: 922px) 100vw, 922px"
                    }
                }
            }
        }
    }
    const data = blogData.landingJson;
    const imageFluid = data.image.childImageSharp.fluid;
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <BlogLayoutWrap>
            <Container fluid>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <ImageWrap>
                            <Image
                                alt={data.title}
                                fluid={imageFluid}
                                align="right"
                            />
                        </ImageWrap>
                    </Col>
                    <Col lg={6}>
                        <BlogLayoutTextWrap>
                            <Heading {...titleStyle}>{parse(data.title)}</Heading>
                            <Text {...textStyle}>{parse(data.desc)}</Text>
                            <ButtonWrap>
                                {/* <Button1 as={Link} to={data.path}>Find out more</Button1> */}
                                <OverlayTrigger
                                    trigger="click"
                                    key='top'
                                    placement='top'
                                    overlay={
                                        <Popover id={`popover-positioned-top`} style={{ maxWidth: '500px', borderTopColor: 'red' }}>
                                            <Popover.Title as="h3" style={{ backgroundColor: 'red', color: 'white' }}>GO TO <Link to='https://signpuns.com/' target='_blank'>SIGNPUNS.COM</Link> TO SIGN UP</Popover.Title>
                                            <Popover.Content style={{ backgroundColor: 'red', color: 'white', fontSize: '16px' }}>
                                                Past Fundraising Events Include;<br />
                                                E-commerce merchandise sales events<br />
                                                Social Media merchandise sales events<br />
                                                Taste of the Town Events<br />
                                                Chili Contests<br />
                                                BBQ Competitions<br />
                                                Food Truck Rodeo's<br />
                                                Wine Tastings<br />
                                                Silent Auctions<br />
                                                Horseshoe Tournaments<br />
                                                Charity Poker Driven Events and Raffles<br />
                                                Cow Pie Bingo (Corn Cob Bingo) and many, many more............<br />
                                                Speak with the SignPuns team today.<br />
                                                Contact Craig at 720.350.8700 or at craig@signpuns.com<br />
                                                to coordinate your fundraising event.<br />
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <Button1>Find out more</Button1>
                                </OverlayTrigger>

                            </ButtonWrap>
                        </BlogLayoutTextWrap>
                    </Col>
                </Row>
            </Container>
        </BlogLayoutWrap>
    )
}

BlogLayout.propTypes = {
    titleStyle: PropTypes.object
}

BlogLayout.defaultProps = {
    titleStyle: {
        as: "h3",
        child: {
            color: 'primary'
        }
    },
    textStyle: {
        mt: '30px'
    }
}

export default BlogLayout;