import React from "react"
// import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-four'
import Footer from '../containers/layout/footer/footer-two'
import PageHeader from '../components/pageheader'
import AudioPlayerForm from '../containers/audio-player'
import SEO from "../components/seo"

const AudioPlayer = () => {

    const pageContext = {
        "breadcrumb": {
            "location": "/audio-player/",
            "crumbs": [
                {
                    "pathname": "/",
                    "crumbLabel": "Home"
                },
                {
                    "pathname": "/audio-player",
                    "crumbLabel": "audio-player"
                }
            ]
        }
    }
    const location = {
        "pathname": "/audio-player/",
    }
    return (
        <Layout location={location}>
            <SEO title="Channel 9 " />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title="How Indian Hills Grabbed The World’s Attention With A Punstoppable Teal Road Sign"
            />
            <main className="site-wrapper-reveal">
                <AudioPlayerForm />
            </main>
            <Footer />
        </Layout>
    )
}

export default AudioPlayer