import React from 'react'
import { Container, Row, Col } from '../../components/menu/ui/wrapper'
import { AddVideoPlayerWrapper, VideoBox } from './video-player.style'

const VideoPlayerForm = () => {
    return (
        <AddVideoPlayerWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        <VideoBox>
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.9news.com/embeds/video/73-8254741/iframe"
                                allowfullscreen="true"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                            ></iframe>
                        </VideoBox>
                    </Col>
                </Row>
            </Container>
        </AddVideoPlayerWrapper>
    )
}

export default VideoPlayerForm;