import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner, Col, Row, Label } from "reactstrap";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAddRecordMutation } from "../../services/vtsgApi";
import { toast, ToastContainer } from 'react-toastify';
import CustomInput from "../form/CustomInput";
import AsyncPaginates from "../form/LazySelect";
import { AsyncPaginate } from "react-select-async-paginate";

const SubmitYourOwnPun = ({ setIsAddModalOpen }) => {
    const [addPun, { isLoading: isPunAddLoading }] = useAddRecordMutation();

    const isDontedOptions = [
        {
            value: true,
            label: "Yes",
        },
        {
            value: false,
            label: "No",
        },
    ];

    const punValidationSchema = Yup.object().shape({
        Name: Yup.string().required("Please enter pun name."),
        IsDonated: Yup.object().required("Please select is donted."),
        Category: Yup.object().required("Please select category."),
        Line1: Yup.string().test(
            "oneOfRequired",
            "Please fill at least line.",
            (_, punLines) =>
                punLines.parent.Line1 ||
                punLines.parent.Line2 ||
                punLines.parent.Line3 ||
                punLines.parent.Line4 ||
                punLines.parent.Line5
        ),
        Line2: Yup.string().test(
            "oneOfRequired",
            "Please fill at least line.",
            (_, punLines) =>
                punLines.parent.Line1 ||
                punLines.parent.Line2 ||
                punLines.parent.Line3 ||
                punLines.parent.Line4 ||
                punLines.parent.Line5
        ),
        Line3: Yup.string().test(
            "oneOfRequired",
            "Please fill at least line.",
            (_, punLines) =>
                punLines.parent.Line1 ||
                punLines.parent.Line2 ||
                punLines.parent.Line3 ||
                punLines.parent.Line4 ||
                punLines.parent.Line5
        ),
        Line4: Yup.string().test(
            "oneOfRequired",
            "Please fill at least line.",
            (_, punLines) =>
                punLines.parent.Line1 ||
                punLines.parent.Line2 ||
                punLines.parent.Line3 ||
                punLines.parent.Line4 ||
                punLines.parent.Line5
        ),
        Line5: Yup.string().test(
            "oneOfRequired",
            "Please fill at least line.",
            (_, punLines) =>
                punLines.parent.Line1 ||
                punLines.parent.Line2 ||
                punLines.parent.Line3 ||
                punLines.parent.Line4 ||
                punLines.parent.Line5
        ),
    });

    const onSubmit = (values) => {

        var formData = new FormData();
        formData.append("Name", values.Name);
        formData.append("Description", values.Description);
        formData.append("Line1", values.Line1);
        formData.append("Line2", values.Line2);
        formData.append("Line3", values.Line3);
        formData.append("Line4", values.Line4);
        formData.append("Line5", values.Line5);
        formData.append("CategoryId", values.Category.Id);
        formData.append("IsDonated", values.IsDonated.value);

        addPun({
            entity: "Pun",
            data: formData,
        }).then((response) => {
            if (!response.error) {
                setIsAddModalOpen(false);
                toast.success("You pun added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
            }
        });
    };

    return (
        <>
            <Formik
                initialValues={{
                    Name: "",
                    Line1: "",
                    Line2: "",
                    Line3: "",
                    Line4: "",
                    Line5: "",
                    Category: "",
                    IsDonated: "",
                }}
                onSubmit={onSubmit}
                validationSchema={punValidationSchema}
            >
                {(formik) => {
                    const {
                        errors,
                        touched,
                        values,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                    } = formik;

                    return (
                        <Form>
                            <CustomInput
                                label="Name"
                                name="Name"
                                fieldErrors={errors.Name}
                                fieldTouched={touched.Name}
                                fieldValue={values.Name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                required
                            />
                            <Row>
                                <Col>
                                    <AsyncPaginates
                                        value={values.Category}
                                        label="Category"
                                        name="Category"
                                        entity="Category"
                                        setFieldValue={setFieldValue}
                                        fieldErrors={errors.Category}
                                        fieldTouched={touched.Category}
                                        handleBlur={handleBlur}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <Label for="IsDonated">
                                            Is Donation
                                            <font color="red"> *</font>
                                        </Label>
                                        <AsyncPaginate
                                            className={`lazySelect`}
                                            name="IsDonated"
                                            value={values.IsDonated}
                                            options={isDontedOptions}
                                            placeholder={`Select IsDontated`}
                                            onBlur={handleBlur}
                                            onChange={(e) => setFieldValue("IsDonated", e)}
                                        />
                                        {touched.IsDonated && errors.IsDonated && (
                                            <font color="red">{errors.IsDonated}</font>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <CustomInput
                                label="Line 1"
                                name="Line1"
                                fieldErrors={errors.Line1}
                                fieldTouched={touched.Line1}
                                fieldValue={values.Line1}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />
                            <Row>
                                <Col>
                                    <CustomInput
                                        label="Line 2"
                                        name="Line2"
                                        fieldErrors={errors.Line2}
                                        fieldTouched={touched.Line2}
                                        fieldValue={values.Line2}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col>
                                    <CustomInput
                                        label="Line 3"
                                        name="Line3"
                                        fieldErrors={errors.Line3}
                                        fieldTouched={touched.Line3}
                                        fieldValue={values.Line3}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomInput
                                        label="Line 4"
                                        name="Line4"
                                        fieldErrors={errors.Line4}
                                        fieldTouched={touched.Line4}
                                        fieldValue={values.Line4}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col>
                                    <CustomInput
                                        label="Line 5"
                                        name="Line5"
                                        fieldErrors={errors.Line5}
                                        fieldTouched={touched.Line5}
                                        fieldValue={values.Line5}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Button
                                disabled={isPunAddLoading}
                                onClick={handleSubmit}
                                className="modal_ok float-left"
                                color="success"
                            >
                                {(isPunAddLoading) && (
                                    <Spinner size="sm" variant="light" />
                                )}{" "}
                                Save
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
            <ToastContainer autoClose={3000} hideProgressBar />
        </>
    );
};

SubmitYourOwnPun.propTypes = {
    editData: PropTypes.object,
    editStatusData: PropTypes.object,
    setIsAddModalOpen: PropTypes.func,
};

export default SubmitYourOwnPun;
