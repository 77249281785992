import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Image } from "react-bootstrap"
import SectionTitle from "../../../components/menu/ui/section-title"
import Demo from "../../../components/demo"
import { DemoAreaWrap } from "./demo-area.style"
import PunbackgroundImage from "./../../../assets/images/logo/VinceTheSignGuyHeaderLogo.png"
import { useLazyGetRecordsQuery } from "../../../services/vtsgApi"
import Button from "../../menu/ui/button"
import { HeadingWrap, PreviewInfo, FrameScreen, PreviewWrap, Dots } from "../../demo/demo.style"

const DisplayPun = ({ sectionTitle, buttonStyle , currentPun }) => {
  const [punRecords, setPunRecords] = useState([]);
  const [top, setTop] = useState(6);
  const [count, setCount] = useState("");

  const [
    getRecords,
    {
      data: punRecordList,
      isFetching: isPunFetching
    },
  ] = useLazyGetRecordsQuery()

  useEffect(() => {
    if(currentPun){
      getRecords({
        entity: "Pun",
        subEntity: "GetRecentPunList",
        top: top,
        filter: `Id ne ${currentPun}`,
        query: "$expand=Media",
      })
    }
  }, [top , currentPun])

  useEffect(() => {
    if (punRecordList) {
      setPunRecords(punRecordList.value)
      setCount(punRecordList["@odata.count"])
    }
  }, [punRecordList]);
  return (
    <DemoAreaWrap id="section-demos">
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              {...sectionTitle}
              subtitle="Our Latest SignPuns"
            // title="<span>Lighthearted Humor </span>To Brighten Our Times"
            />
          </Col>
        </Row>
        <Row>
          {punRecords && punRecords.map((demo, i) => {
            return (
              <Col lg={4} md={6} key={i}>
                {demo.Media ?
                  <PreviewWrap>
                    <Dots className="mb-3">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </Dots>
                    <FrameScreen>
                      <Image src={demo.Media?.CloudinaryAddress}
                        alt="Preview Hero" />
                      <PreviewInfo>
                        {demo && <HeadingWrap>{demo.UserName}</HeadingWrap>}
                      </PreviewInfo>
                    </FrameScreen>
                  </PreviewWrap>
                  : <Demo content={demo} punImage={PunbackgroundImage} />}
              </Col>
            )
          })}
        </Row>
        <Col lg={12}>
          <div className="d-flex justify-content-center">
            {punRecords.length === count ?
              <Button bgcolor="#1CC0DB" {...buttonStyle} color="black" disabled>No more records</Button> :
              <Button bgcolor="#1CC0DB" {...buttonStyle} color="black" onClick={() => setTop((prev) => prev + 6)} disabled={isPunFetching} > {!isPunFetching ? "See more" : "Loading..."}</Button>}
          </div>
        </Col>
      </Container>
    </DemoAreaWrap >
  )
}

DisplayPun.propTypes = {
  sectionTitle: PropTypes.object,
  buttonStyle: PropTypes.object,
}

DisplayPun.defaultProps = {
  sectionTitle: {
    titleColor: "black",
    align: "center",
    weight: "1000",
    fs: "30px",
  },
  buttonStyle: {
    skin: 'light',
    mt: '40px',
    responsive: {
      large: {
        mt: '10px',
      }
    },
    fontWeight: "700",
    fontSize: "20px",
    height: "60px",
    size: "xsmall",
    lineheight: "60px",
    p: "0 20px"
  },
}

export default DisplayPun
