import React from 'react'
import { Container, Row, Col } from '../../components/menu/ui/wrapper'
import { AddAudioPlayerWrapper, AudioBox } from './audio-player.style'

const AudioPlayerForm = () => {
    return (
        <AddAudioPlayerWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        <AudioBox>
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://wp-cpr.s3.amazonaws.com/uploads/2019/07/cpr-mlamp_indian-hills-sign_09212018-180921-111205.mp3"
                                allowfullscreen="true"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                            ></iframe>
                        </AudioBox>
                    </Col>
                </Row>
            </Container>
        </AddAudioPlayerWrapper>
    )
}

export default AudioPlayerForm;