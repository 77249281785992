import React from "react"
// import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-four'
// import Footer from '../containers/layout/footer/footer-two'
// import Hero from '../containers/landing/hero'
// import Feature from '../containers/landing/feature'
// import Demos from '../containers/landing/demo-area'
// import BlogLayout from '../containers/landing/blog-layout'
// import Plugins from '../containers/landing/plugins'
import AddPunsArea from '../containers/addPuns'
import PageHeader from '../components/pageheader'
// import Header from "../containers/layout/header"
import Footer from "../containers/layout/footer/footer-two"
import SEO from "../components/seo"



const AddPunsPage = () => {
  const pageContext = {
    "breadcrumb": {
      "location": "/addPuns/",
      "crumbs": [
        {
          "pathname": "/",
          "crumbLabel": "Home"
        },
        {
          "pathname": "/addPuns",
          "crumbLabel": "addPuns"
        }
      ]
    }
  }
  const location = {
    "pathname": "/addPuns",
  }

  return (
    <Layout location={location}>
      <SEO />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title="Add Puns"
      />
      <main className="site-wrapper-reveal">
        <AddPunsArea />
      </main>
      <Footer/>
    </Layout>
  )
}

export default AddPunsPage
