import { configureStore } from "@reduxjs/toolkit"
import { vtsgApi } from "../services/vtsgApi"

export const store = configureStore({
  reducer: {
    [vtsgApi.reducerPath]: vtsgApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(vtsgApi.middleware),
})
