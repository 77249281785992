import React from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import TitleBarBg from '../../data/images/bg/title-bar-01-bg.jpg'
// import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
// import { useStaticQuery, graphql } from "gatsby"
import { PageHeaderWrap } from './page-header.style';

const PageHeader = ({ pageContext, location, title }) => {
    const { breadcrumb: { crumbs } } = pageContext;
    const disableLinks = [
        "/elements",
        "/category",
        "/profile",
        "/date",
        "/tag",
        "/page",
        "/blog",
        "/blog/page",
        "/blogs",
        "/services"
    ]
    const customCrumbLabel = location.pathname.toLowerCase();
    const crumbLabelArr = customCrumbLabel.split('/');
    const label = crumbLabelArr[crumbLabelArr.length - 1]
    const labelArr = label.split('-');
    // const imageData = useStaticQuery(graphql`
    //     query breadCrumbImgQuery {
    //         file(relativePath: {eq: "images/bg/title-bar-01-bg.jpg"}) {
    //             childImageSharp {
    //                 fluid(maxWidth: 1920, maxHeight: 400, quality: 100) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }

    // `);
    const imageData = {
        "file": {
            "childImageSharp": {
                "fluid": {
                    "base64": TitleBarBg,
                    "aspectRatio": 4.8,
                    "src": TitleBarBg,
                    "srcSet": `${TitleBarBg} 480w,\n${TitleBarBg} 960w,\n${TitleBarBg} 1920w`,
                    "sizes": "(max-width: 1920px) 100vw, 1920px"
                }
            }
        }
    }
    return (
        <PageHeaderWrap fluid={imageData.file.childImageSharp.fluid}>
            <div>
                <div tag="a" className='breadcrumbItem'>{title}</div>
                <Breadcrumb tag="nav">
                    {
                        crumbs.map((label, index) =>
                            <BreadcrumbItem tag="a" href="#" key={index}><Link to={label?.pathname}>{label?.crumbLabel}</Link></BreadcrumbItem>)
                    }
                </Breadcrumb>
            </div>

        </PageHeaderWrap >
    )
}

export default PageHeader
