import React from 'react';
import PropTypes from 'prop-types'
// import Img from 'gatsby-image'
import SliderImage from '../../image'
import { truncateString } from '../../../containers/utils/utilFunctions'
import {
    BoxImgWrapper,
    BoxImgInner,
    BoxImgMedia,
    BoxImgContent,
    HeadingWrap,
    TextWrap,
    BoxImgLink
} from './box-image.style'
import { Image } from 'react-bootstrap';

const BoxImage = ({ imageSrc, title, desc, path, ...boxStyles }) => {
    const { headingStyle, descStyle, ...restStyle } = boxStyles;
    let boxImage;
    if (imageSrc.fixed && typeof imageSrc.fixed !== 'function') {
        boxImage = <Image fixed={imageSrc.fixed} alt={title} />;
    } else if (imageSrc.fluid) {
        boxImage = <SliderImage fluid={imageSrc.fluid} alt={title} />
    } else {
        boxImage = <Image src={imageSrc} alt={title} />
    }
    return (
        <a href={path} target="_blank">
            <BoxImgWrapper {...restStyle}>
                <BoxImgInner>
                    {imageSrc && (
                        <BoxImgMedia>
                            {boxImage}
                        </BoxImgMedia>
                    )}
                    <BoxImgContent>
                        {title && <HeadingWrap as='h5' {...headingStyle}>{title}</HeadingWrap>}
                        {desc && <TextWrap {...descStyle}>{truncateString(desc, 80)}</TextWrap>}
                    </BoxImgContent>
                    <BoxImgLink to={path} target="_blank">{title}</BoxImgLink>
                </BoxImgInner>
            </BoxImgWrapper>
        </a>
    )
}

BoxImage.propTypes = {
    content: PropTypes.object,
    imageSrc: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    title: PropTypes.string
}

BoxImage.defaultProps = {
    path: "/"
}


export default BoxImage; 