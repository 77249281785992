import React from 'react'
import { Container, Row, Col } from '../../components/menu/ui/wrapper'
import { FansFormWrapper } from './fans-form.style'

const FansForm = () => {
    return (
        <FansFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        Fans Page ....
                    </Col>
                </Row>
            </Container>
        </FansFormWrapper>
    )
}

export default FansForm;