import React from 'react'
import Icon from '../../components/menu/ui/icon'
import Text from '../../components/menu/ui/text'
// import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../components/menu/ui/wrapper'
import { ProblemBox, ProblemTextBox, SolutionTextBox } from './vegan-service.style'

const VeganService = () => {

    // const veganData = useStaticQuery(graphql`
    // query {
    //     landingJson(id: {eq: "vegan_features"}) {
    //       id
    //       problem
    //       solution  
    //     }
    //   }

    // `)
    const veganData = {
        "landingJson": {
            "id": "vegan_features",
            "problem": "The vegan sign was a controversial newsmaker",
            "solution": "I poke fun at almost everyone (including myself)... and sometimes when I least expect it… things happen to the sign! When I posted about how Turning Vegan Could Be A Big Missed Steak... a Vegan restaurant owner and friend came up to Indian Hills and swapped around our sign letters to say “turning vegan is awesome”. They also decided to steal the remaining letters… which did not sit well with any of us. After messing with the sign and stealing our letters, they identified themselves when they went to a cow pasture a few miles away to pet cows… took pics of the cow petting, and posted them to Facebook... along with the re-done sign pics. I was copied on this about an hour later, and the letter recovery mission was underway! There were many fascinating aspects to this story... My followers blasted the Vegan restaurant for stealing my letters…don’t piss my people off! I was very touched! The letters were returned… with kind of an apology… and they gave me some VooDoo vegan donuts… which were very tasty! Lettuce all learn from this story… :)"
        }
    }
    const vegans = veganData.landingJson;
    const { problem, solution } = vegans;
    return (
        <ProblemBox>
            <Container>
                <Row>
                    <Col md={5}>
                        <Icon iconName="fal fa-long-arrow-right" />
                        <ProblemTextBox>
                            {problem}
                        </ProblemTextBox>
                    </Col>
                    <Col md={{ span: 6, offset: 1 }}>
                        <Icon iconName="fal fa-check" />
                        <SolutionTextBox>
                            <Text>{solution}</Text>
                        </SolutionTextBox>
                    </Col>
                </Row>
            </Container>
        </ProblemBox>
    )
}

export default VeganService;