import './App.css';
import Layout from './containers/layout/layout';
import ContactUsPage from './pages/contact-us'
import AddPunsPage from './pages/addPuns';
import TopicalPunPage from './pages/topicalPuns';
import HumorPage from './pages/lightHumor';
import TradePage from './pages/trades';
import EventPage from './pages/events';
import VideoPlayer from './pages/video-player';
import VeganPage from './pages/vegan';
import AudioPlayer from './pages/audio-player';
import Testimonial from './pages/fans/testimonials';
import FanPage from './pages/fans';
import Frenemies from './pages/fans/frenemies';
import HomePage from './pages/homePage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ShopifyStore from './pages/shopifyStore';
import ErrorArea from './containers/error-area';
import PrivacyPolicyPage from './pages/privacy-policy';
import SubmitYourOwnPun from './components/pun/SubmiYourOwnPun';

const App = ({ location }) => {
  return (
    <Router>
      <Layout location={location}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/fans/frenemies" component={Frenemies} />
          <Route exact path="/fans" component={FanPage} />
          <Route exact path="/fans/testimonials" component={Testimonial} />
          <Route exact path="/audio-player" component={AudioPlayer} />
          <Route exact path="/vegan" component={VeganPage} />
          <Route exact path="/video-player" component={VideoPlayer} />
          <Route exact path="/events" component={EventPage} />
          <Route exact path="/trades" component={TradePage} />
          <Route exact path="/lightHumor" component={HumorPage} />
          <Route exact path="/topicalPuns" component={TopicalPunPage} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/addPuns" component={AddPunsPage} />
          <Route exact path="/store" component={ShopifyStore} />
          <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route exact path="/submit-your-own-pun" component={SubmitYourOwnPun} />
          <Route component={ErrorArea} />
        </Switch>
      </Layout>
    </Router>
  );

}

export default App;
