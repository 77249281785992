import React from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import AdsImage from "../../assets/images/ads.png"
import Heading from '../menu/ui/heading'

const Advertisment = ({ headingPrimary }) => {
    return (
        <>
            <Container>
                <Heading {...headingPrimary}>Check out Vince the Sign Guy on the Mandy Connell Show!</Heading>
                <div className='d-flex justify-content-center'>
                    <a href='https://www.google.com/amp/s/www.ivoox.com/03-20-23-interview-vince-the-sign-guy-audios-mp3_rf_104879531_amp_1.html' target="_blank">
                        <img src={AdsImage} />
                    </a>
                </div>
            </Container>
        </>
    )
}

Advertisment.propTypes = {
    headingPrimary: PropTypes.object
}
Advertisment.defaultProps = {
    headingPrimary: {
        as: 'h3',
        color: 'black',
        letterspacing: '2px',
        fontSize: '35px',
        fontFamily: "CerebriSans",
        mb: "50px"
    },
}

export default Advertisment