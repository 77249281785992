import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../components/menu/ui/wrapper'
import AddpunsForm from '../../components/forms/addPuns'
import { AddPunsFormWrapper } from './addPuns-form-area.style'

const AddPunsFormArea = ({ headingStyle, textStyle }) => {
    return (
        <AddPunsFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                    <AddpunsForm />
                    </Col>
                </Row>
            </Container>
        </AddPunsFormWrapper>
    )
}

AddPunsFormArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
}

AddPunsFormArea.defaultProps = {
    headingStyle: {
        as: "h3",
        position: "relative",
        pl: '34px',
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
        layout: 'quote'
    },
    textStyle: {
        mt: '15px',
        fontSize: '18px',
        ml: '34px',
        color: '#696969'
    }
}

export default AddPunsFormArea;