import React from "react"
import PageHeader from './../components/pageheader'
// import CTAArea from '../containers/global/cta-area/section-one'
import ContactFormArea from '../containers/contact-us/contact-form-area'
import ContactInfoArea from '../containers/contact-us/contact-info-area'
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header/header-four"
import Footer from "../containers/layout/footer/footer-two"
import SEO from "../components/seo"

const ContactUsPage = () => {
    const pageContext = {
        "breadcrumb": {
            "location": "/contact-us/",
            "crumbs": [
                {
                    "pathname": "/",
                    "crumbLabel": "Home"
                },
                {
                    "pathname": "/contact-us",
                    "crumbLabel": "contact-us"
                }
            ]
        }
    }
    const location = {
        "pathname": "/contact-us/",
    }
    return (
        <Layout location={location}>
            <SEO title="Contact Us" />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title="Contact Us"
            />
            <main className="site-wrapper-reveal">
                <ContactFormArea />
                <ContactInfoArea />
                {/* <CTAArea /> */}
            </main>
            <Footer />
        </Layout>
    )
}

export default ContactUsPage