import React from "react"
import PropTypes from "prop-types"
import LogoVector from "./../../assets/images/logo/VinceTheSignGuyHeaderNewLogo.png"
import { LogoWrapper } from "./logo.style"
import { NavLink } from "react-router-dom"

const Logo = props => {
  return (
    <LogoWrapper {...props}>
      <NavLink to="/" className="p-0">
        <img src={LogoVector} className="light-logo" alt="logo" />
      </NavLink>
    </LogoWrapper>
  )
}

Logo.propTypes = {
  justifycontent: PropTypes.string,
}

Logo.defaultProps = {
  justifycontent: "flex-start",
}

export default Logo
