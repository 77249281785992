import styled, { css } from 'styled-components'
import { device } from '../theme';
// import {device} from '../../theme'

export const AddVideoPlayerWrapper = styled.section`
    padding-top: 100px;
    padding-bottom: 64px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 44px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 25px;
    }
`;
export const VideoBox = styled.div`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
  height: 680px;
  width: 1200px;
`;
