import React from 'react'
import { Container, Row, Col } from '../../../components/menu/ui/wrapper'
import { TopicalPunWrapper } from './topical-puns-form.style'

const TopicalPunForm = () => {
    return (
        <TopicalPunWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        Topical Puns Page...
                    </Col>
                </Row>
            </Container>
        </TopicalPunWrapper>
    )
}

export default TopicalPunForm;