import React from 'react'
import { Container, Row, Col } from '../../../components/menu/ui/wrapper'
import { TestimonialFormWrapper } from './testimonials-form.style'

const TestimonialForm = () => {
    return (
        <TestimonialFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        Testimonial Page ....
                    </Col>
                </Row>
            </Container>
        </TestimonialFormWrapper>
    )
}

export default TestimonialForm;