import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap"

const AddModal = ({ title, message, toggle, isAddModalOpen }) => {
  return (
    <>
      <Modal className='justify-content-center' onHide={toggle} show={isAddModalOpen} size="lg">
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>
          {message}
        </Modal.Body>
      </Modal>
    </>
  );
};

AddModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.object,
  color: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
  xl: PropTypes.bool,
};

AddModal.defaultProps = {
  title: "",
  message: {},
  colored: false,
};

export default AddModal
