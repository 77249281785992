import React from "react"
// import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-four'
import Footer from '../../containers/layout/footer/footer-two'
import PageHeader from '../../components/pageheader'
import TestimonialForm from '../../containers/fans/testimonials'
import SEO from "../../components/seo"

const Testimonial = () => {
    const pageContext = {
        "breadcrumb": {
            "location": "/fans/testimonials/",
            "crumbs": [
                {
                    "pathname": "/",
                    "crumbLabel": "Home"
                },
                {
                    "pathname": "/fans",
                    "crumbLabel": "fans"
                },
                {
                    "pathname": "/fans/testimonials",
                    "crumbLabel": "testimonials"
                }
            ]
        }
    }
    const location = {
        "pathname": "/fans/testimonials/",
    }
    return (
        <Layout location={location}>
            <SEO title="Testimonials" />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title="Testimonials"
            />
            <main className="site-wrapper-reveal">
                <TestimonialForm />
            </main>
            <Footer />
        </Layout>
    )
}

export default Testimonial