function useFormUrl(props) {
    // const getFormUrl = useStaticQuery(graphql`
    //     query GET_FORM_URL {
    //         site {
    //             siteMetadata {
    //                 getform
    //             }
    //         }
    //     }      
    // `)
    const getFormUrl = {
        "site": {
            "siteMetadata": {
                "getform": "https://getform.io/f/5ffd7578-b2c4-44ea-94f9-a434f9b1859e"
            }
        }
    }
    const { site: { siteMetadata: { getform } } } = getFormUrl
    return getform;
}

export default useFormUrl