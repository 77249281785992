import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const CustomInput = ({
  label,
  name,
  type,
  fieldErrors,
  fieldTouched,
  fieldValue,
  handleBlur,
  handleChange,
  readonly = false,
  required = false,
  isChecked = false
}) => {
  return type === "checkbox" ? (
    <FormGroup>
      <Input
        className="ml-0"
        name={name}
        id={name}
        type={type}
        placeholder={name}
        required={required}
        value={fieldValue}
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={readonly}
        checked={isChecked}
      />
      <Label for={name} className="ml-4">
        {label}
        {required && <font color="red"> *</font>}
      </Label>
      {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
    </FormGroup>
  ) : (
    <FormGroup>
      <Label for={name}>
        {label}
        {required && <font color="red"> *</font>}
      </Label>
      <Input
        name={name}
        id={name}
        type={type}
        placeholder={name}
        required={required}
        value={fieldValue}
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={readonly}
      />
      {fieldTouched && fieldErrors && <font color="red">{fieldErrors}</font>}
    </FormGroup>
  );
};

export default CustomInput;
