import styled from 'styled-components'
// import BackgroundImage from 'gatsby-background-image'
import { animateUpDown } from '../../../assets/css/animations'
import { device } from '../../../containers/theme';
// import { device } from '../../../theme'

export const HeroWrapper = styled.div`
    padding-top: 100px;
    padding-bottom: 120px;
    position: relative;
    height: 1020px;
    align-items: center;
    background-size: cover;
    background-image:url(${(props) => props.src});
    overflow: hidden;
    background-position: 50% 50% !important;
    background-color: transparent;
    .wrapperContent{
        position: relative;
        top: 20px;
    }
    @media ${device.xlarge}{
        height: 780px;
    }
    @media ${device.large}{
        height: 620px;
    }
    @media ${device.medium}{
        height: auto;
        padding-top: 115px;
        padding-bottom: 80px;
    }
    @media (max-width:1600px){
        .wrapperContent{
            top: 20px;
        }
    }
    
    .pr-img {
        &-01{
            position: absolute !important;
            top: 5%;
            left: 30%;
            width: 300px;
            @media (max-width:1600px){
                width:210px;
                top:15%;
                left:35%;
            }
            @media ${device.medium}{
                width:170px;
                top:15%;
                left:5%;
            }
            @media ${device.small}{
                width: 90px;
                top: 30%;
                left: 5%;
            }
        }
        &-02{
            position: absolute !important;
            top: 12%;
            right: 6%;
            width: 125px;
            @media ${device.small}{
                right: -5%;
            }
        }
        &-03{
            position: absolute !important;
            bottom: 10%;
            right: -10%;
            width: 435px;
            @media ${device.xlarge}{
                width: 250px;
            }
            @media ${device.medium}{
                width: 200px;
                right: -20%;
            }
        }
        &-04{
            position: absolute !important;
            bottom: 20%;
            right: 18%;
            width: 356px;
            @media ${device.medium}{
                bottom: 5%;
                right: 18%;
            }
            @media ${device.small}{
                bottom: 2%;
                right: 50%;
            }
        }
    }
    .animation_images{
        animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
        animation-name: ${animateUpDown};
        animation-iteration-count: infinite;
        &.one{
            animation-duration: 3.2s;
        }
        &.two{
            animation-duration: 3.8s;
        }
    }
`;

export const HeroWrapperText = styled.div`
    z-index: 4;
    position: relative;
    @media ${device.medium}{
        text-align: center;
    }
`;

export const PreviewInnerImg = styled.div`
    @media ${device.medium}{
        max-width: 500px;
        margin: auto;
    }
`;

export const PreviewInnerDiv = styled.div`
    sizes: (max-width: 939px) 100vw, 939px;
    border: 5px solid #dee2e6;
    background: White;
    @media ${device.medium}{
        max-width: 500px;
        margin: auto;
    }
`;

export const CardWrapper = styled.div`
  background-color:#8DD2DD;
  padding: 0px 4px 0px 4px;
`
export const EmptyPun = styled.div`
 height:38px;
`
export const CardContent = styled.div`
    border-bottom: 1px solid #C2E7EE;
    border-top: 2px solid #C2E7EE;
    font-family: BabasNeue;
    font-weight: 700;
    color: black;
    font-size: 50px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
    @media ${device.xlarge} {
        font-size: 30px;
        height: 40px;
      }
    @media ${device.small} {
        font-size: 20px;
        height: 30px;
      }
`