import React from "react"
// import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-four'
import Footer from '../containers/layout/footer/footer-two'
import PageHeader from '../components/pageheader'
// import CTAArea from '../containers/global/cta-area/section-one'
import EventForm from '../containers/humor-section/Events'
import SEO from "../components/seo"

const EventPage = () => {
  const pageContext = {
    "breadcrumb": {
      "location": "/events/",
      "crumbs": [
        {
          "pathname": "/",
          "crumbLabel": "Home"
        },
        {
          "pathname": "/events",
          "crumbLabel": "events"
        }
      ]
    }
  }

  const location = {
    "pathname": "/events/",
  }

  return (
    <Layout location={location}>
      <SEO title="Events" />
      <Header />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title="Events"
      />
      <main className="site-wrapper-reveal">
        <EventForm />
      </main>
      <Footer />
    </Layout>
  )
}

export default EventPage