import React from 'react'
import SEO from '../components/seo'
import Footer from '../containers/layout/footer/footer-two'
import Header from '../containers/layout/header/header-four'
import Layout from '../containers/layout/layout'

const ShopifyStore = () => {
    return (
        <Layout>
            <SEO title="ShopifyStore" />
            <Header />
            <main className="site-wrapper-reveal calendar-store">
                <div id="shopify-wrapper"></div>
            </main>
            <Footer />
        </Layout>
    )
}

export default ShopifyStore