import styled, { css } from 'styled-components'
import { device } from './../../../../containers/theme'

export const SectionTitleWrap = styled.div`
    text-align: ${props => props.align};
    margin-bottom: ${props => props.mb};
    margin-top: ${props => props.mt};
    cursor: ${props => props.cursor};   
    font-family: ${props => props.family}; 
    ${props => props.responsive && css`
    ${props => props.responsive.medium && css`
    @media ${device.medium}{
        margin-bottom: ${props => props.responsive.medium.mb};
        margin-bottom: ${props => props.responsive.medium.mt};
        text-align: ${props => props.responsive.medium.align};
    }
    `}
    ${props => props.responsive.small && css`
    @media ${device.small}{
        margin-bottom: ${props => props.responsive.small.mb};
        margin-bottom: ${props => props.responsive.small.mt};
        text-align: ${props => props.responsive.small.align};
    }
    `}
    `}
    h6{
        color: ${props => props.titleColor ? props.titleColor : "#ababab"};
        text-align: ${props => props.align};
        font-weight:  ${props => props.weight ? props.weight : "400"};
        ${props => props.layout === 1 && css`
        margin-top: -5px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: ${props => props.fs ? props.fs : "14px"};
        letter-spacing: 3px;
        line-height: 1.43;
        `}
        ${props => props.layout === 2 && css`
        font-size: 18px;
        `}
    }
    h3{
        ${props => props.layout === 2 && css`
        margin-bottom: 20px;
        `}
        span{
            color: ${props => props.theme.colors.themeColor};
        }
    }
    p{
        color: ${props => props.color}; 
    }
    `;