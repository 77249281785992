import React from "react"
// import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-four'
import Footer from '../../containers/layout/footer/footer-two'
import PageHeader from '../../components/pageheader'
import FansForm from '../../containers/fans'
import SEO from "../../components/seo"

const FanPage = () => {

    const pageContext = {
        "breadcrumb": {
            "location": "/fans/",
            "crumbs": [
                {
                    "pathname": "/",
                    "crumbLabel": "Home"
                },
                {
                    "pathname": "/fans",
                    "crumbLabel": "fans"
                }
            ]
        }
    }

    const location = {
        "pathname": "/fans",
    }

    return (
        <Layout location={location}>
            <SEO title="Fans" />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title="Fans"
            />
            <main className="site-wrapper-reveal">
                <FansForm />
            </main>
            <Footer />
        </Layout>
    )
}

export default FanPage