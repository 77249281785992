import React from 'react'
import { Container, Row, Col } from '../../../components/menu/ui/wrapper'
import { FrenemiesFormWrapper } from './frenemies-form.style'

const FrenemieForm = () => {
    return (
        <FrenemiesFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        Frenemies Page ....
                    </Col>
                </Row>
            </Container>
        </FrenemiesFormWrapper>
    )
}

export default FrenemieForm;