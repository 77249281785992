import React from 'react'
import { Container, Row, Col } from '../../../components/menu/ui/wrapper'
import { TradeWrapper } from './trades-form.style'

const TradeForm = () => {
    return (
        <TradeWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        Trade Page...
                    </Col>
                </Row>
            </Container>
        </TradeWrapper>
    )
}

export default TradeForm;