import React from 'react';
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Container, Row, Col } from 'react-bootstrap'
import { FooterWrap, FooterInner, FooterContent, ButtonWrap } from './footer.style'
import Button from '../../../../components/menu/ui/button'
import Heading from '../../../../components/menu/ui/heading'
import MountainWallpaper from '../../../../data/images/bg/Mountain Wallpaper.jpg'

const Footer = ({ heading, subheading }) => {
    // const footerData = useStaticQuery(graphql`
    //     query FooterDataQuery{
    //         landingJson(id: {eq: "preview-footer-data"}) {
    //             title
    //             subtitle
    //             path
    //         }
    //         file(relativePath: {eq: "images/bg/Mountain Wallpaper.jpg"}) {
    //             childImageSharp {
    //                 fluid(maxWidth: 1920, maxHeight: 628, quality: 100) {
    //                     ...GatsbyImageSharpFluid
    //                 }
    //             }
    //         }
    //     }
    // `)

    const footerData = {
        "landingJson": {
            "title": "Why Not Become a SignPuns Insider?<br>You can register today to get on the list.",
            "subtitle": "Click below to join the Vince the Sign Guy Insiders List",
            "path": "http://eepurl.com/hp7T7P"
        },
        "file": {
            "childImageSharp": {
                "fluid": {
                    "base64": MountainWallpaper,
                    "aspectRatio": 3.0573248407643314,
                    "src": MountainWallpaper,
                    "srcSet": `${MountainWallpaper} 480w,\n${MountainWallpaper} 960w,\n${MountainWallpaper} 1920w,\n${MountainWallpaper} 2560w`,
                    "sizes": "(max-width: 1920px) 100vw, 1920px"
                }
            }
        }
    }

    const { title, subtitle } = footerData.landingJson;
    const bgImage = footerData.file.childImageSharp.fluid;
    return (
        <FooterWrap>
            <FooterInner fluid={bgImage}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <FooterContent>
                                {title && <Heading {...heading}>{parse(title)}</Heading>}
                                {subtitle && <Heading {...subheading}>{subtitle}</Heading>}
                                <ButtonWrap>
                                    <Button
                                        to="https://mailchi.mp/signpuns.com/insiders-landing-page"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        skin="light">Join the Insiders Club</Button>
                                </ButtonWrap>
                            </FooterContent>
                        </Col>
                    </Row>
                </Container>
            </FooterInner>
        </FooterWrap>
    )
}

Footer.propTypes = {
    heading: PropTypes.object,
    subheading: PropTypes.object
}

Footer.defaultProps = {
    heading: {
        color: '#fff'
    },
    subheading: {
        as: 'h6',
        color: '#fff',
        letterSpacing: '7px',
        mt: '25px'
    }
}

export default Footer;