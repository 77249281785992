import styled from "styled-components";

export const ProblemBox = styled.div `
    padding-bottom: 50px;
    padding-left:10px;
    margin-bottom: 44px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
    margin-top:20px;
`;
export const ProblemTextBox = styled.div `
    display: flex;
    font-size: 20px;
    margin-top:-30px;
    margin-left: 20px;
`;
export const SolutionTextBox = styled.div `
    display: flex;
    font-size: 15px;
    margin-left: 20px;
    margin-top:-25px;
`;