import React from 'react'
import { Container, Row, Col } from '../../../components/menu/ui/wrapper'
import { EventWrapper } from './event-form.style'

const EventForm = () => {
    return (
        <EventWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={6}>
                        Event Page...
                    </Col>
                </Row>
            </Container>
        </EventWrapper>
    )
}

export default EventForm;