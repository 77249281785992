import React from "react"
// import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-four'
import Footer from '../containers/layout/footer/footer-two'
import PageHeader from '../components/pageheader'
// import CTAArea from '../containers/global/cta-area/section-one'
import TradeForm from '../containers/humor-section/Trades'
import SEO from "../components/seo"

const TradePage = () => {
    const pageContext = {
        "breadcrumb": {
            "location": "/trades/",
            "crumbs": [
                {
                    "pathname": "/",
                    "crumbLabel": "Home"
                },
                {
                    "pathname": "/trades",
                    "crumbLabel": "trades"
                }
            ]
        }
    }

    const location = {
        "pathname": "/trades/",
    }
    return (
        <Layout location={location}>
            <SEO title="Trades" />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title="Trades"
            />
            <main className="site-wrapper-reveal">
                <TradeForm />
            </main>
            <Footer />
        </Layout>
    )
}

export default TradePage