import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Row, Col } from '../../menu/ui/wrapper'
import Form, { FormGroup, Input, Textarea, Error } from '../../menu/ui/form'
import Button from '../../menu/ui/button'
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from 'reactstrap'
import Cookies from 'universal-cookie';

const AddpunsForm = (props) => {
	const { loginWithRedirect, isAuthenticated } = useAuth0();
	const { register, handleSubmit, errors } = useForm({
		mode: "onBlur"
	})

	const [serverState, setServerState] = useState({
		submitting: false,
		status: null
	});

	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
		}
	};

	const onSubmit = (data, e) => {
		const form = e.target;
		setServerState({ submitting: true });
		if (isAuthenticated) {
			const cookies = new Cookies();
			var accessToken = cookies.get('accessToken');
			// props.addRecord(data, 'Pun', 'SET_PUNS', accessToken)
			// 	.then(res => handleServerResponse(true, "Thanks! for Adding Pun", form))
			form.reset();
		}
		else {
			loginWithRedirect();
		}
	}
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Row gutters={{ lg: 20 }}>
				<Col lg={12}>
					<FormGroup mb="20px">
						<Input
							type="text"
							name="name"
							id="name"
							placeholder="Name *"
							ref={register("name", { required: 'Name is required' })}
						/>
						<Error>{errors?.name && errors?.name?.message}</Error>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<FormGroup mb="30px">
						<Textarea
							name="description"
							id="description"
							placeholder="Please describe what you need."
							ref={register("description", {
								required: 'Description is required',
								maxLength: {
									value: 50,
									message: "Maximum length is 50"
								},
								minLength: {
									value: 10,
									message: "Minimum length is 10"
								}
							})}
						></Textarea>
						<Error>{errors?.description && errors?.description?.message}</Error>
					</FormGroup>
				</Col>
			</Row>
			<Row gutters={{ lg: 20 }}>
				<Col lg={10}>
					<FormGroup mb="20px">
						<div className="d-flex">
							<Input
								name="isDonated"
								id="isDonated"
								type="checkbox"
								style={{ width: '15px' }}
								ref={register("isDoneted")}
							/>
							<div style={{ float: "right" }} className="mt-3">
								Do you agree that SignPuns will own the pun you are submitting?
							</div>
						</div>
					</FormGroup>
				</Col>
				<Col lg={8}>
					<FormGroup mt="25px">
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<Button type="submit" disabled={serverState.submitting}>
						{serverState.submitting && <Spinner color="dark" style={{ width: '1rem', height: '1rem' }} />}
						{"\n\n\n\n\n " + "Add Pun"}
					</Button>
					{serverState.status && (
						<p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
							{serverState.status.msg}
						</p>
					)}
				</Col>
			</Row>
		</Form>
	)
}

// const mapStateToProps = ({ DBOperation }) => {
// 	return {
// 		dbOperation: DBOperation
// 	}
// }

// export default connect(
// mapStateToProps, {
// addRecord,
// }
export default AddpunsForm