import React from "react"
import PropTypes from "prop-types"
import { NavLinkWrap, AnchorTag, SmoothScroll } from "./navlink.style"

export const NavLink = ({ path, children, ...props }) => {
  const internal = /^\/(?!\/)/.test(path)
  const isHash = path.startsWith("#")
  if (internal) {
    return (
      <NavLinkWrap to={path} {...props} >
        {children}
      </NavLinkWrap>
    )
  }
  if (isHash) {
    return (
      <SmoothScroll href={path} {...props}>
        {children}
      </SmoothScroll>
    )
  }
  return (
    <AnchorTag href={path} {...props} target="_blank">
      {children}
    </AnchorTag>
  )
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
}

NavLink.defaultProps = {
  path: "/",
}
